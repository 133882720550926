const seasonConfig = [
  {
    season: 1,
    dateRange: ["2024-04-13", "2024-04-28"],
    assetRegistered: {
      "denchu": 4217,
      "mh": 0,
      "hh": 0,
    }
  },
  {
    season: 2,
    dateRange: ["2024-05-04", "2024-05-17"],
    assetRegistered: {
      "denchu": 5992,
      "mh": 0,
      "hh": 0,
    }
  },
  {
    season: 3,
    dateRange: ["2024-05-18", "2024-05-31"],
    assetRegistered: {
      "denchu": 4473,
      "mh": 0,
      "hh": 0,
    }
  },
  {
    season: 4,
    dateRange: ["2024-06-01", "2024-06-14"],
    assetRegistered: {
      "denchu": 1989,
      "mh": 0,
      "hh": 0,
    }
  },
  {
    season: 5,
    dateRange: ["2024-06-15", "2024-06-29"],
    assetRegistered: {
      "denchu": 3852,
      "mh": 0,
      "hh": 0,
    }
  },
  {
    season: "Preseason at Tokyo",
    dateRange: ["2024-07-26", "2024-09-05"],
    assetRegistered: {
      "denchu": 12640,
      "mh": 0,
      "hh": 0,
    }
  },
  {
    season: "東京本シーズン1",
    dateRange: ["2024-09-14", "2024-09-30"],
    assetRegistered: {
      "denchu": 12702,
      "mh": 0,
      "hh": 0,
    }
  },
  {
    season: "東京本シーズン2",
    dateRange: ["2024-10-01", "2024-10-31"],
    assetRegistered: {
      "denchu": 12695,
      "mh": 100,
      "hh": 0,
    }
  },
];

const gameSpaceConfig = [
  {
    "game_space_id": "cluqif2kh0001bkru8jw2dy2p",
    "game_term_id": "cluqif2ki0002bkru7sx16ooj",
    "game_field_id": "cluqif2ke0000bkru36755hft",
    "game_term_id_from_term": "cluqif2ki0002bkru7sx16ooj",
    "start_datetime": "2024-04-10T10:00:00.000Z",
    "end_datetime": "2024-04-28T07:00:00.000Z",
    "name": "Season1"
  },
  {
    "game_space_id": "cluqif2kl0003bkruh1j198rz",
    "game_term_id": "cluqif2kl0004bkruegybgurb",
    "game_field_id": "cluqif2ke0000bkru36755hft",
    "game_term_id_from_term": "cluqif2kl0004bkruegybgurb",
    "start_datetime": "2024-05-04T02:00:00.000Z",
    "end_datetime": "2024-05-17T07:00:00.000Z",
    "name": "Season2"
  },
  {
    "game_space_id": "cluqif2kl0005bkru1bcf4qdh",
    "game_term_id": "cluqif2kl0006bkrub6ijbjca",
    "game_field_id": "cluqif2ke0000bkru36755hft",
    "game_term_id_from_term": "cluqif2kl0006bkrub6ijbjca",
    "start_datetime": "2024-05-18T00:00:00.000Z",
    "end_datetime": "2024-05-31T07:00:00.000Z",
    "name": "Season3"
  },
  {
    "game_space_id": "cluqif2kl0007bkruhep048bx",
    "game_term_id": "cluqif2kl0008bkru1915fl1k",
    "game_field_id": "cluqif2ke0000bkru36755hft",
    "game_term_id_from_term": "cluqif2kl0008bkru1915fl1k",
    "start_datetime": "2024-06-01T00:00:00.000Z",
    "end_datetime": "2024-06-14T07:00:00.000Z",
    "name": "Season4"
  },
  {
    "game_space_id": "cluqif2kn0009bkruhcbn3pow",
    "game_term_id": "cluqif2kn000abkrucxu13h1t",
    "game_field_id": "cluqif2ke0000bkru36755hft",
    "game_term_id_from_term": "cluqif2kn000abkrucxu13h1t",
    "start_datetime": "2024-06-15T00:00:00.000Z",
    "end_datetime": "2024-06-29T07:00:00.000Z",
    "name": "Season5"
  },
  {
    "game_space_id": "clywb28o20000bnoofdaxc5ee",
    "game_term_id": "clywb28o20001bnoo4ep9d7tz",
    "game_field_id": "cluqif2ke0000bkru36755hft",
    "game_term_id_from_term": "clywb28o20001bnoo4ep9d7tz",
    "start_datetime": "2024-07-25T22:00:00.000Z",
    "end_datetime": "2024-09-05T09:00:00.000Z",
    "name": "Preseason at Tokyo"
  },
  {
    "game_space_id": "cm0lxmtgv0000zpndbhg715yr",
    "game_term_id": "cm0lxmtgv0001zpndeqtdah7m",
    "game_field_id": "cluqif2ke0000bkru36755hft",
    "game_term_id_from_term": "cm0lxmtgv0001zpndeqtdah7m",
    "start_datetime": "2024-09-14T03:00:00.000Z",
    "end_datetime": "2024-09-30T09:00:00.000Z",
    "name": "東京本シーズン1"
  },
  {
    "game_space_id": "cm0lxmtgw0002zpnd9mpk6q9c",
    "game_term_id": "cm0lxmtgw0003zpndc0sy49vo",
    "game_field_id": "cluqif2ke0000bkru36755hft",
    "game_term_id_from_term": "cm0lxmtgw0003zpndc0sy49vo",
    "start_datetime": "2024-09-30T22:00:00.000Z",
    "end_datetime": "2024-10-31T09:00:00.000Z",
    "name": "東京本シーズン2"
  },
  // {
  //   "game_space_id": "cm0lxmtgw0004zpndc7108ned",
  //   "game_term_id": "cm0lxmtgw0005zpndedhh98fd",
  //   "game_field_id": "cluqif2ke0000bkru36755hft",
  //   "game_term_id_from_term": "cm0lxmtgw0005zpndedhh98fd",
  //   "start_datetime": "2024-10-31T22:00:00.000Z",
  //   "end_datetime": "2024-11-30T09:00:00.000Z",
  //   "name": "東京本シーズン3"
  // },
]

export default {
  seasonConfig,
  gameSpaceConfig,
}
