
import { put, call, takeEvery, select, all } from "redux-saga/effects";
import _ from 'lodash';
import moment from 'moment-timezone';
import { getDataFromKpiDash } from "api";
import { PICTREE, ENDPOINT } from "../../constants";

import {
  setPageTopLoader,
  setMissionData,
  setMissionSummaryData,
  setMissionRankingData,
} from 'actions';

const isStarted = (startTime, currentTime) => {
  return moment(startTime).isBefore(currentTime);
};

const isHappening = (startTime, endTime, currentTime) => {
  let hppening = false;
  if (moment(startTime).isBefore(currentTime)) {
    if (moment(currentTime).isBefore(endTime)) {
      hppening =  true;
    }
  }
  return hppening;
};

const getBetweenMin = (startTime, firstTime) => {
  const occurredTime = moment(startTime);
  const achievedTime = moment(firstTime);
  return achievedTime.diff(occurredTime, 'minutes')
};

export function* handleMissionData() {
  try {
    yield put(setPageTopLoader(true));
    // let dateFrom = yield select(startDate);
    // dateFrom = moment(dateFrom).tz("Asia/Tokyo").format("YYYY-MM-DD");
    // let dateTo = yield select(endDate);
    // dateTo = moment(dateTo).tz("Asia/Tokyo").format("YYYY-MM-DD");

    const sqlQueryMissionAchieveCount = {
      query: `
        SELECT gs.id, gt.start_datetime as 'missionStart'
        , gt.end_datetime as 'missionEnd'
        , mn.name AS 'missionName'
        , COUNT(me.id) AS 'achievedNum'
        , CONVERT_TZ(gt.start_datetime, '+00:00', '+09:00') AS occurred_datetime_jst
        ,CONVERT_TZ(gt.end_datetime, '+00:00', '+09:00') AS end_datetime_jst
        , CONVERT_TZ(MIN(me.occurred_datetime), '+00:00', '+09:00') AS achieved_datetime_first
        FROM game_space gs
        LEFT JOIN mission_event me ON gs.id = me.game_space_id AND me.type = 'MISSION_ACHIEVED'
        JOIN mission_name mn ON gs.game_term_id = mn.mission_id AND mn.language_code = 'JA'
        JOIN game_term gt ON gt.id = gs.game_term_id
        WHERE gt.start_datetime < now() AND gs.id != 'cm0zde85z000407506byxr3bu'
        AND gs.id != 'cm1p2fudc000o07501pjyy0ki'
        GROUP BY gs.id, gt.start_datetime, gt.start_datetime, mn.name
        ORDER BY missionStart ASC;
      `,
    };
    const sqlQueryCountAchieversUnique = {
      query: `
        SELECT ps.name, me.type, COUNT(*) as achievedNum
        FROM mission_event me
        JOIN game_space gs ON gs.id = me.game_space_id
        JOIN player_state ps ON me.player_id = ps.player_id
        JOIN mission_name mn ON gs.game_term_id = mn.mission_id
        WHERE me.type = 'MISSION_ACHIEVED' AND me.game_space_id != 'cm0zde85z000407506byxr3bu'
        AND me.game_space_id != 'cm1p2fudc000o07501pjyy0ki'
        GROUP BY ps.player_id, ps.name
        ORDER BY achievedNum DESC;
      `
    };

    const sqlQueryCountNonAchieversUnique = {
      query: `
        SELECT ps.name, me.type, COUNT(*) as achievedNum
        FROM mission_event me
        JOIN game_space gs ON gs.id = me.game_space_id
        JOIN player_state ps ON me.player_id = ps.player_id
        JOIN mission_name mn ON gs.game_term_id = mn.mission_id
        WHERE me.type != 'MISSION_ACHIEVED' AND me.game_space_id != 'cm0zde85z000407506byxr3bu'
        AND me.game_space_id != 'cm1p2fudc000o07501pjyy0ki'
        GROUP BY ps.player_id, ps.name
        ORDER BY achievedNum DESC;
      `
    };

    const sqlQueryAchieverCountByMission = {
      query: `
        SELECT mn.mission_id AS mission_id, mn.name AS mission_name, COUNT(*) as achieverNum
        FROM mission_event me
        JOIN game_space gs ON gs.id = me.game_space_id
        JOIN mission_name mn ON gs.game_term_id = mn.mission_id
        JOIN player_state ps ON me.player_id = ps.player_id
        WHERE type = 'MISSION_ACHIEVED' AND mn.language_code = 'JA' AND game_space_id != 'cm0zde85z000407506byxr3bu'
        AND game_space_id != 'cm1p2fudc000o07501pjyy0ki'
        GROUP BY mn.mission_id
        ORDER BY occurred_datetime ASC
      `,
    };
    const sqlQureyAssetCount = {
      query: `
        SELECT
            pae.game_space_id AS game_space_id, mn.mission_id AS mission_id, mn.name AS mission_name,
            COUNT(DISTINCT mn.mission_id) AS mission_count,
            COUNT(DISTINCT ps.player_id) AS player_count,
            COUNT(DISTINCT ppa.file_name) AS pic_count,
            COUNT(DISTINCT pae.power_asset_id) AS asset_count,
            CONVERT_TZ(pae.occurred_datetime, '+00:00', '+09:00') AS occurred_datetime_jst,
            CONVERT_TZ(gt.end_datetime, '+00:00', '+09:00') AS end_datetime_jst
        FROM
            power_asset_event pae
        JOIN
            photo_of_power_asset ppa ON ppa.power_asset_id = pae.power_asset_id
            AND ppa.player_id = pae.player_id
            AND ppa.game_space_id = pae.game_space_id
        JOIN
            game_space gs ON gs.id = pae.game_space_id
        JOIN mission_name mn ON gs.game_term_id = mn.mission_id
        JOIN
            game_term gt ON gt.id = gs.game_term_id
        JOIN
            player_state ps ON pae.player_id = ps.player_id
        JOIN
            power_asset_detail_of_tepco padtep ON pae.power_asset_id = padtep.power_asset_id
        JOIN
            review_request_photo_bind rrpb ON rrpb.photo_of_power_asset_id = ppa.id
        JOIN
            power_asset pa ON pa.id = pae.power_asset_id
        WHERE
            pae.type = 'PHOTOGRAPH'
            AND gt.type = 'MISSION'
            AND pa.type = 'MISSION_ASSET'
            AND mn.language_code = 'JA'
            AND pae.game_space_id != 'cm0zde85z000407506byxr3bu'
            AND pae.game_space_id != 'cm1p2fudc000o07501pjyy0ki'
        GROUP BY pae.game_space_id
        ORDER BY
            pae.occurred_datetime ASC
      `
    };

    const sqlQureyPlayerCount = {
      query: `
        SELECT
            ps.name,
            COUNT(DISTINCT ppa.file_name) AS pic_count,
            COUNT(DISTINCT pae.power_asset_id) AS asset_count
        FROM
            power_asset_event pae
        JOIN
            photo_of_power_asset ppa ON ppa.power_asset_id = pae.power_asset_id
            AND ppa.player_id = pae.player_id
            AND ppa.game_space_id = pae.game_space_id
        JOIN
            game_space gs ON gs.id = pae.game_space_id
        JOIN
            game_term gt ON gt.id = gs.game_term_id
        JOIN
            player_state ps ON pae.player_id = ps.player_id
        JOIN
            power_asset_detail_of_tepco padtep ON pae.power_asset_id = padtep.power_asset_id
        JOIN
            review_request_photo_bind rrpb ON rrpb.photo_of_power_asset_id = ppa.id
        JOIN
            power_asset pa ON pa.id = pae.power_asset_id
        JOIN mission_name mn ON gs.game_term_id = mn.mission_id
        WHERE
            pae.type = 'PHOTOGRAPH'
            AND gt.type = 'MISSION'
            AND pa.type = 'MISSION_ASSET'
            AND pae.game_space_id != 'cm0zde85z000407506byxr3bu'
            AND pae.game_space_id != 'cm1p2fudc000o07501pjyy0ki'
        GROUP BY ps.player_id
        ORDER BY pic_count DESC
      `
    };
    const sqlQueryCountEntrySubmitted = {
      query: `
        SELECT COUNT(*) AS total_count
        FROM (
            SELECT
                pae.power_asset_id,
                ps.player_id,
                pae.occurred_datetime
            FROM
                power_asset_event pae
            JOIN
                photo_of_power_asset ppa ON ppa.power_asset_id = pae.power_asset_id
                AND ppa.player_id = pae.player_id
                AND ppa.game_space_id = pae.game_space_id
            JOIN
                game_space gs ON gs.id = pae.game_space_id
            JOIN
                game_term gt ON gt.id = gs.game_term_id
            JOIN
                player_state ps ON pae.player_id = ps.player_id
            JOIN
                power_asset_detail_of_tepco padtep ON pae.power_asset_id = padtep.power_asset_id
            JOIN
                review_request_photo_bind rrpb ON rrpb.photo_of_power_asset_id = ppa.id
            JOIN
                power_asset pa ON pa.id = pae.power_asset_id
            WHERE
                pae.type = 'PHOTOGRAPH'
                AND gt.type = 'MISSION'
                AND pa.type = 'MISSION_ASSET'
            GROUP BY
                pae.power_asset_id, ps.player_id, occurred_datetime
        ) AS subquery;
      `
    };

    const [dataMissionAchieveCountTemp, dataMissionAchieverTemp, dataMissionNonAchieverTemp,
      dataAchieverCountByMissionTemp, dataAssetCountTemp, dataPlayerCountTemp,
      /*dataCountTotalEntryTemp*/] =
    yield all([
      call(getDataFromKpiDash, sqlQueryMissionAchieveCount, ENDPOINT.GET_DATA_API),
      call(getDataFromKpiDash, sqlQueryCountAchieversUnique, ENDPOINT.GET_DATA_API),
      call(getDataFromKpiDash, sqlQueryCountNonAchieversUnique, ENDPOINT.GET_DATA_API),
      call(getDataFromKpiDash, sqlQueryAchieverCountByMission, ENDPOINT.GET_DATA_API),
      call(getDataFromKpiDash, sqlQureyAssetCount, ENDPOINT.GET_DATA_API),
      call(getDataFromKpiDash, sqlQureyPlayerCount, ENDPOINT.GET_DATA_API),
      // call(getDataFromKpiDash, sqlQueryCountEntrySubmitted, ENDPOINT.GET_DATA_API),
    ]);

    const missionData = JSON.parse(dataMissionAchieveCountTemp.body);
    const totalAchievedCount = _.sumBy(missionData, 'achievedNum');
    const countAchievedZero = _.size(_.filter(missionData, { achievedNum: 0 }));
    const countAchieved = missionData.length - countAchievedZero;

    const achieverData = JSON.parse(dataMissionAchieverTemp.body);
    const nonAchieverData = JSON.parse(dataMissionNonAchieverTemp.body);
    const achieveCountByMissionData = JSON.parse(dataAchieverCountByMissionTemp.body);
    const assetCountData = JSON.parse(dataAssetCountTemp.body);
    const playerCountData = JSON.parse(dataPlayerCountTemp.body);
    // const dataCountTotalEntry = JSON.parse(dataCountTotalEntryTemp.body);
    // console.log("LOG achieveCountByMissionData:", achieveCountByMissionData);
    // console.log("LOG playerCountData:", playerCountData);
    // console.log("LOG dataCountTotalEntry:", dataCountTotalEntry);

    let missions = [], missionObj = {}, missionsStarted = [];
    const currentTime = moment().tz("Asia/Tokyo").format("YYYY-MM-DD HH:mm");
    _.each(missionData, function(m) {
      missionObj.missionId = m.missionId;
      missionObj.missionName = m.missionName;
      missionObj.missionStart = moment(m.missionStart).tz("Asia/Tokyo").format("YYYY-MM-DD HH:mm");
      missionObj.missionEnd = moment(m.missionEnd).tz("Asia/Tokyo").format("YYYY-MM-DD HH:mm");
      missions.push(missionObj);
      if (isStarted(moment(m.missionStart).tz("Asia/Tokyo").format("YYYY-MM-DD HH:mm"), currentTime)) {
        missionsStarted.push(missionObj);
      }
      missionObj = {};
    });

    const totalMissionCount = _.sumBy(assetCountData, 'mission_count');
    const totalPicCount = _.sumBy(assetCountData, 'pic_count');
    const totalAssetCount = _.sumBy(assetCountData, 'asset_count');
    const totalPlayerCount = playerCountData.length;
    //const totalAchievedAndNonAchievedCount = totalAchievedCount + nonAchieverData.length;
    const totalAttendCount = _.sumBy(assetCountData, 'player_count');

    // mission table data
    let missionTableData = [], firstTimeMinArray = [];
    _.each(missionData, function(d) {
      const achiever = _.find(assetCountData, { game_space_id: d.id });
      missionObj.missionId = d.id;
      missionObj.occurredDatetimeJst = d.occurred_datetime_jst.slice(0, 16).replace('T', ' ');
      missionObj.endDatetimeJst = d.end_datetime_jst.slice(0, 16).replace('T', ' ');
      missionObj.firstAchieveMin = d.achieved_datetime_first !== null
      ? getBetweenMin(d.occurred_datetime_jst, d.achieved_datetime_first)
      : "-"
      missionObj.name = d.missionName;
      missionObj.achieverCount = d.achievedNum;
      missionObj.playerCount = achiever !== undefined ? achiever.player_count : 0; //d.player_count ? d.player_count : 0;
      missionObj.picCount = achiever !== undefined ? achiever.pic_count : 0;
      missionObj.assetCount = achiever !== undefined ? achiever.asset_count : 0;
      missionObj.happening = isHappening(missionObj.occurredDatetimeJst, missionObj.endDatetimeJst, currentTime)
      missionTableData.push(missionObj);
      if (missionObj.firstAchieveMin !== "-") {
        firstTimeMinArray.push(missionObj.firstAchieveMin);
      }
      missionObj = {};
    });
    const allMinSpent = _.sum(firstTimeMinArray);

    // _.each(assetCountData, function(d) {
    //   const achiever = _.find(achieveCountByMissionData, { mission_id: d.mission_id });
    //   missionObj.missionId = d.mission_id;
    //   missionObj.occurredDatetimeJst = d.occurred_datetime_jst.slice(0, 16).replace('T', ' ');
    //   missionObj.name = d.mission_name;
    //   missionObj.achieverCount = achiever ? achiever.achieverNum : 0;
    //   missionObj.playerCount = d.player_count;
    //   missionObj.picCount = d.pic_count;
    //   missionObj.assetCount = d.asset_count;
    //   missionTableData.push(missionObj);
    //   missionObj = {};
    // })

    const missionSummaryData = {
      // registNumber: missionData.length,
      startedNumber: missionsStarted.length,
      countAchieved: countAchieved,
      countAchievers: achieverData.length,
      totalAchievedCount: totalAchievedCount,
      totalAchievedAndNonAchievedCount: totalAttendCount, //totalAchievedAndNonAchievedCount,
      totalMissionCount: totalMissionCount,
      totalPlayerCount: totalPlayerCount,
      totalPicCount: totalPicCount,
      totalAssetCount: totalAssetCount,
      missionTableData: missionTableData,
      avgMinutesTillFirstAchieve: (allMinSpent / firstTimeMinArray.length).toFixed(0),
    }

    yield put(setMissionData(missions));
    yield put(setMissionSummaryData(missionSummaryData));
    yield put(setPageTopLoader(false));
  } catch (error) {
    console.log(error);
  }
}

export function* handleMissionRankingData() {
  try {
    const sqlQueryMissionRanking = {
      query: `
        SELECT ps.player_id, ps.name, ps.team_code, COUNT(*) as achievedNum
        FROM mission_event me
        JOIN game_space gs ON gs.id = me.game_space_id
        JOIN player_state ps ON me.player_id = ps.player_id
        JOIN mission_name mn ON gs.game_term_id = mn.mission_id
        WHERE me.type = 'MISSION_ACHIEVED' AND me.game_space_id != 'cm0zde85z000407506byxr3bu'
        AND me.game_space_id != 'cm1p2fudc000o07501pjyy0ki'
        GROUP BY ps.player_id, ps.name, ps.team_code
        ORDER BY achievedNum DESC;
      `
    }

    // SELECT *, COUNT(*) as achievedNum
    // FROM mission_event me
    // JOIN game_space gs ON gs.id = me.game_space_id
    // JOIN mission_name mn ON gs.game_term_id = mn.mission_id
    // JOIN player_state ps ON me.player_id = ps.player_id
    // WHERE type = 'MISSION_ACHIEVED' AND game_space_id != 'cm0zde85z000407506byxr3bu'
    // GROUP BY ps.player_id
    // ORDER BY achievedNum DESC

    // SELECT ps.name, ps.team_code, COUNT(*) as achievedNum
    // FROM mission_event me
    // JOIN game_space gs ON gs.id = me.game_space_id
    // JOIN player_state ps ON me.player_id = ps.player_id
    // WHERE me.type = 'MISSION_ACHIEVED' AND me.game_space_id != 'cm0zde85z000407506byxr3bu'
    // GROUP BY ps.player_id, ps.name, ps.team_code
    // ORDER BY achievedNum DESC;

    const [dataRankingTemp] =
    yield all([
      call(getDataFromKpiDash, sqlQueryMissionRanking, ENDPOINT.GET_DATA_API),
    ]);
    const rankingData = JSON.parse(dataRankingTemp.body);
    let rankingTableData = [], rannkingObj = {};
    _.each(rankingData, function(d) {
      rannkingObj.playerId = d.player_id;
      rannkingObj.name = d.name;
      rannkingObj.team = d.team_code;
      rannkingObj.achievedCount = d.achievedNum;
      rankingTableData.push(rannkingObj);
      rannkingObj = {};
    })
    yield put(setMissionRankingData(rankingTableData));
  } catch (error) {
    console.log(error);
  }
}
export default function* watchMapSettings() {
  yield takeEvery(PICTREE.GET_MISSION_DATA, handleMissionData);
  yield takeEvery(PICTREE.GET_MISSION_RANKING_DATA, handleMissionRankingData);
}