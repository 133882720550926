import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import { Grid, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@material-ui/core';
import * as H from "helper";
import moment from 'moment-timezone';

const useStyles = makeStyles((theme) =>
  createStyles({
    box: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(1),
      borderRight: "1px solid #dcdcdc",
    },
    tableHead: {
      height: 48,
      backgroundColor: "#fafafa",
    },
    tableRow: {
      height: 32,
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
    },
    tableRowNone: {
      height: 126,
    },
  })
);

const MissionTable = ({
  missionTableData,
}) => {
  const classes = useStyles();
  return (
    <Grid container spacing={1}>
      <Grid item sm={12}>
        <TableContainer component={Paper} style={{minHeight: 600}}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead className={classes.tableHead}>
              <TableRow>
              <TableCell align="center">#</TableCell>
                <TableCell align="center">ミッション名</TableCell>
                <TableCell align="center">開始終了日時</TableCell>
                <TableCell align="center">時間</TableCell>
                <TableCell align="center" style={{minWidth: 80}}>初回達成<br />所要(分)</TableCell>
                <TableCell align="center" style={{minWidth: 80}}>ミッション<br />達成人数</TableCell>
                <TableCell align="center" style={{minWidth: 80}}>ミッション<br />参加者数</TableCell>
                <TableCell align="center" style={{minWidth: 80}}>撮影枚数</TableCell>
                <TableCell align="center" style={{minWidth: 80}}>撮影基数</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {missionTableData !== undefined && missionTableData.length > 0 &&
               missionTableData.map((row, index) => (
                <TableRow key={row.missionId}
                  // className={classes.tableRow}
                  className={`${classes.tableRow} ${row.happening ? 'grow-gradient' : ''}`}
                  style={{
                    backgroundColor: row.achieverCount === 0 && !row.happening ? "#eee" : "none"
                  }}
                >
                  <TableCell component="th" scope="row" align="left">{index+1}</TableCell>
                  <TableCell component="th" scope="row" align="left">{row.name}
                    {row.happening ? " [開催中]" : ""}
                  </TableCell>
                  <TableCell align="center">{row.occurredDatetimeJst}~{moment(row.endDatetimeJst).format("HH:mm")}</TableCell>
                  <TableCell align="center">
                    {moment(row.endDatetimeJst).diff(moment(row.occurredDatetimeJst), "hours")}h
                  </TableCell>
                  <TableCell align="center">{row.firstAchieveMin}</TableCell>
                  <TableCell align="center">{row.achieverCount}人</TableCell>
                  <TableCell align="center">{row.playerCount}人</TableCell>
                  <TableCell align="center">{row.picCount}枚</TableCell>
                  <TableCell align="center">{row.assetCount}基</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

MissionTable.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  missionTableData: state.pictree.missionSummaryData.missionTableData,
});

const mapDispatchToProps = (dispatch) => ({
  //setCurrentModule: (param) => dispatch(setCurrentModule(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(MissionTable));
