import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Chip } from '@material-ui/core';
import {
  getMissionRankingData,
} from "actions";
import * as H from "helper";

function setChipLabel(label, classes) {
  const colorKeyName =
    "chipColor" + label.toLowerCase().replace(/[^A-Z0-9]+/gi, "");
  return (
    <Chip className={classes[colorKeyName]} size="small" label={label} />
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    chipColorvolt: {
      paddingTop: theme.spacing(.5),
      backgroundColor: "#fd5842",
      color: "#fff",
    },
    chipColorampere: {
      paddingTop: theme.spacing(.3),
      backgroundColor: "#4ba0c1",
      color: "#fff",
    },
    chipColorwatt: {
      paddingTop: theme.spacing(.5),
      backgroundColor: "#6eb26e",
      color: "#fff",
    },
    tableHead: {
      height: 48,
      backgroundColor: "#fafafa",
    },
    tableRow: {
      height: 32,
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
    },
    tableRowNone: {
      height: 126,
    },
  })
);

const MissionRanking = ({
  missionRankingData,
  getMissionRankingData,
}) => {
  const classes = useStyles();

  useEffect(() => {
    getMissionRankingData();
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item sm={12}>
        <TableContainer component={Paper} style={{minHeight: 600}}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead className={classes.tableHead}>
              <TableRow>
              <TableCell align="center">#</TableCell>
                <TableCell align="left">プレイヤーID</TableCell>
                <TableCell align="left">プレイヤー名</TableCell>
                <TableCell align="center">チーム</TableCell>
                <TableCell align="center" style={{minWidth: 80}}>ミッション<br />達成数</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {missionRankingData !== undefined && missionRankingData.length > 0 &&
               missionRankingData.map((row, index) => (
                <TableRow key={index} className={classes.tableRow}>
                  <TableCell component="th" scope="row" align="left">{index+1}</TableCell>
                  <TableCell component="th" scope="row" align="left">{row.playerId}</TableCell>
                  <TableCell component="th" scope="row" align="left">{row.name}</TableCell>
                  <TableCell align="center">{setChipLabel(row.team, classes)}</TableCell>
                  <TableCell align="center">{row.achievedCount}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

MissionRanking.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  missionRankingData: state.pictree.missionRankingData,
});

const mapDispatchToProps = (dispatch) => ({
  getMissionRankingData: (param) => dispatch(getMissionRankingData(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(MissionRanking));
