import { all } from 'redux-saga/effects';

import affiliateSaga from './affiliateSaga';
import campaignSaga from './campaignSaga';
import pageSaga from './pageSaga';
import mapSaga from './mapSaga';
import homeSaga from './homeSaga';
import puzzleRegSaga from './puzzleRegSaga';
import puzzleGeoSaga from './puzzleGeoSaga';
import puzzleEventsSaga from './puzzleEventsSaga';
import userSaga from './userSaga';
import cohortSaga from './cohortSaga';
import salesSaga from './salesSaga';
import activeUsersSaga from './activeUsersSaga';
import nftDepSaga from './nftDepSaga';
import byCountrySaga from './nftdep/byCountrySaga';
import byPmidSaga from './nftdep/byPmidSaga';
import byGameSaga from './nftdep/byGameSaga';
import byCampaignSaga from './nftdep/byCampaignSaga';
import nftDepTrendSaga from './nftdep/trendSaga';
import nftOwnTrendSaga from './nftdep/nftOwnTrendSaga';
import nftDepTxHistorySaga from './nftdep/txHistorySaga';
import nftHoldersSaga from './nftdep/nftHoldersSaga';
import stakingSaga from './nftdep/stakingSaga';
import walletBalanceSaga from './nftdep/balanceSaga';
import jtcbNftTrendSaga from './jtcb/nftTrendSaga';
import jtcbScolarrSaga from './jtcb/scholarSaga';
import avgRevenueSaga from './kpi/averageRevenueSaga';
import kpiSalesSaga from './kpi/kpiSalesSaga';
import cbScolarrSaga from './cookinburger/scholarSaga';
import lfGamePaidTrendSaga from './luckyfarmer/gamePaidTrendSaga';
import grGamePaidTrendSaga from './graffitiracer/gamePaidTrendSaga';
import cbGamePaidTrendSaga from './cookinburger/gamePaidTrendSaga';
import InGameSalesSaga from './salesIngame/InGameSalesSaga';
import InGameSalesTrendSaga from './salesIngame/InGameSalesTrendSaga';
import InGameSalesTxHistorySaga from './salesIngame/InGameSalesTxHistorySaga';
import nftSalesSaga from './salesNft/nftSalesSaga';
import pictreeHomeSaga from './pictree/pictreeHomeSaga';
import pictreeGameRegistrationSaga from './pictree/pictreeGameRegistrationSaga';
import pictreeSalesSaga from './pictree/pictreeSalesSaga';
import pictreePointSaga from './pictree/pictreePointSaga';
import pictreeGamePhotoShootSaga from './pictree/pictreeGamePhotoShootSaga';
import pictreeGameCheckInSaga from './pictree/pictreeGameCheckInSaga';
import pictreeUsersSaga from './pictree/pictreeUsersSaga';
import pictreeMissionSaga from './pictree/pictreeMissionSaga';
import a2eCampaignsSaga from './a2e/a2eCampaignsSaga';

export default function* rootSaga() {
    yield all([
        affiliateSaga(), campaignSaga(), pageSaga(), mapSaga(), homeSaga(),
        puzzleRegSaga(), puzzleGeoSaga(), puzzleEventsSaga(), userSaga(),
        cohortSaga(), salesSaga(), activeUsersSaga(), nftDepSaga(), byCountrySaga(), byPmidSaga(), byGameSaga(), byCampaignSaga(),
        nftDepTrendSaga(), nftOwnTrendSaga(), nftDepTxHistorySaga(), nftHoldersSaga(), stakingSaga(), walletBalanceSaga(),
        jtcbNftTrendSaga(), jtcbScolarrSaga(), avgRevenueSaga(), kpiSalesSaga(), cbScolarrSaga(), lfGamePaidTrendSaga(), grGamePaidTrendSaga(),
        cbGamePaidTrendSaga(), InGameSalesSaga(), InGameSalesTrendSaga(), InGameSalesTxHistorySaga(),
        nftSalesSaga(),
        pictreeHomeSaga(), pictreeGameRegistrationSaga(), pictreeSalesSaga(), pictreePointSaga(),
        pictreeGamePhotoShootSaga(), pictreeGameCheckInSaga(), pictreeUsersSaga(), pictreeMissionSaga(),
        a2eCampaignsSaga(),
    ]);
}